@if (generalLoading) {
  <div class="loader-container" [ngClass]="{'low-opacity': isTable}">
    <div class="spinner-ripple">
      <div class="circle">
        <div></div>
        <div></div>
      </div>
    </div>
  </div>
}

@if (basicLoading) {
  <div class="loader-container">
    <div class="spinner-ripple">
      <div class="circle">
        <div></div>
        <div></div>
      </div>
    </div>
  </div>
}
