<header class="mb-4">
  <h4>{{ "theme.copy_theme_dialog.title" | translate }}</h4>
</header>
<mat-dialog-content>
  <form
    id="copy-form"
    class="row"
    [formGroup]="copyForm"
    (ngSubmit)="onSubmit()"
  >
    <div class="col-lg-12 col-md-12 col-sm-12">
      <label for="title">{{
        "theme.copy_theme_dialog.form.theme_name" | translate
      }}</label>
      <input
        type="text"
        name="title"
        id="title"
        class="wsm-input validate d-block w-100"
        formControlName="title"
        autocomplete="off"
      />
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions class="justify-content-end">
  <button
    class="wsm-btn wsm-btn-outline me-3"
    [mat-dialog-close]="{type: 'back'}"
  >
    {{ "theme.copy_theme_dialog.form.cancel_button" | translate }}
  </button>
  <button
    class="wsm-btn wsm-btn-primary submit-btn"
    type="submit"
    form="copy-form"
  >
    @if (!isLoading) {
      <span>{{ "theme.copy_theme_dialog.form.save_button" | translate }}</span>
    }
    @if (isLoading) {
      <div class="spinner-border spinner-border-sm text-light" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    }
  </button>
</mat-dialog-actions>
