import {Component, Input, OnInit} from '@angular/core';
import { NgClass } from '@angular/common';

@Component({
    selector: 'app-loading',
    templateUrl: './loading.component.html',
    styleUrls: ['./loading.component.scss'],
    standalone: true,
    imports: [NgClass]
})
export class LoadingComponent implements OnInit {

  @Input() generalLoading = true;
  @Input() basicLoading = false;
  @Input() isTable: boolean;

  constructor() {
    //
  }

  ngOnInit(): void {
    //
  }
}
