<header>
  <div
    [ngClass]="{
      'justify-content-between mb-3':
        fromParent === 'modal' || fromParent === 'modal-page',
    }"
    class="d-flex align-items-center gap-2"
  >
    @if (backButton) {
      <button
        (click)="onGoBack()"
        class="wsm-btn wsm-btn-outline-icon wsm-btn-tiny-icon"
      >
        <i class="fa-regular fa-chevron-left"></i>
      </button>
    }
    <div class="d-flex flex-column">
      @if (fromParent === "static") {
        <h6 class="mb-0">
          {{ "theme.form.title" | translate }}
        </h6>
      }
      @if (fromParent === "modal") {
        <h6 class="mb-0">
          {{ "theme.form.title_dialog" | translate }}
        </h6>
      }
      @if (fromParent === "modal-page") {
        <h6 class="mb-0">
          {{ "theme.form.title_page_dialog" | translate }}
        </h6>
      }
      @if (fromParent === "static") {
        <p class="wsm-text-grayed">
          {{ "theme.form.help_description" | translate }}
        </p>
      }
    </div>
    @if (fromParent === "static" && isSuperOrOrgAdmin) {
      <mat-slide-toggle class="ms-3" [(ngModel)]="isReadOnly">
        @if (isReadOnly) {
          <span>{{ "theme.only_you_can_edit" | translate }}</span>
        }
        @if (!isReadOnly) {
          <span>{{ "theme.anyone_can_edit" | translate }}</span>
        }
      </mat-slide-toggle>
    }
    @if (fromParent === "modal" || fromParent === "modal-page") {
      <button
        (click)="onCloseDialog()"
        class="wsm-btn wsm-btn-outline-icon wsm-btn-tiny-icon"
      >
        <i class="fa-regular fa-close"></i>
      </button>
    }
  </div>
  <hr />
  <p class="mt-3">
    <small class="d-flex wsm-text-grayed mb-3">
      {{ "common.fields_required" | translate }}
    </small>
  </p>
</header>
<div [ngClass]="{'is-dialog': fromParent !== 'static'}" class="row">
  <form [formGroup]="themeForm" class="col-lg-6 col-md-6 col-sm-12">
    <div class="row form-row">
      <mat-accordion multi>
        <mat-expansion-panel class="mat-elevation-z0">
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{ "theme.form.section.general" | translate }}
            </mat-panel-title>
          </mat-expansion-panel-header>
          @if (fromParent === "static") {
            <div class="row mt-3 mb-3">
              <div class="col-12">
                <label
                  >{{ "theme.form.theme_name" | translate }}&nbsp;<span
                    class="required-mark"
                    >*</span
                  ></label
                >
                <input
                  formControlName="name"
                  type="text"
                  class="wsm-input w-100"
                  placeholder="{{
                    'theme.form.placeholder.theme_name' | translate
                  }}"
                />
              </div>
            </div>
          }

          @if (fromParent === "static") {
            <div class="row mt-3 mb-3">
              <div class="col-12">
                <div class="d-flex justify-content-between">
                  <label>{{
                    "theme.form.branding_widget_text" | translate
                  }}</label>
                  @if (
                    themeForm.get("brandingText") &&
                    themeForm.get("brandingText")?.value
                  ) {
                    <span class="wsm-text-white">
                      {{ themeForm.get("brandingText")?.value.length }} / 49
                    </span>
                  }
                </div>
                <input
                  formControlName="brandingText"
                  type="text"
                  class="wsm-input w-100"
                  maxlength="49"
                  placeholder="{{
                    'theme.form.placeholder.branding_widget_text' | translate
                  }}"
                />
              </div>
            </div>
          }

          <div
            [ngClass]="{'mt-3': fromParent === 'modal-page'}"
            class="row mb-3 align-items-center"
          >
            <!--BEGIN MODAL PAGE-->
            @if (fromParent === "modal-page") {
              <div class="col-6">
                <div class="col-12">
                  <label
                    >{{ "theme.form.page_name" | translate }}&nbsp;<span
                      class="required-mark"
                      >*</span
                    ></label
                  >
                  <input
                    formControlName="name"
                    type="text"
                    class="wsm-input w-100"
                    placeholder="{{
                      'theme.form.placeholder.page_name' | translate
                    }}"
                  />
                </div>
              </div>
            }
            <!--END MODAL PAGE-->

            @if (fromParent === "static") {
              <div class="col-6">
                <app-upload-input
                  [disabled]="themeForm.get('logo')?.disabled!"
                  [parentFile]="parentLogoFile!"
                  [noDispatch]="noDispatchInputFile"
                  [parentFileName]="themeForm.get('logo')?.value"
                  (resFile)="setLogo($event!)"
                  label="{{ logoUploadInput }}"
                  placeholder="{{ placeholderUploadInput }}"
                ></app-upload-input>
              </div>
            }

            <div
              [ngClass]="{
                'col-12 mt-3': fromParent === 'modal',
                'col-6': fromParent === 'static' || 'modal-page',
              }"
              class=""
            >
              <app-upload-input
                [disabled]="themeForm.get('backgroundImage')?.disabled!"
                [parentFile]="parentBackgroundFile!"
                [parentFileName]="themeForm.get('backgroundImage')?.value"
                [noDispatch]="noDispatchInputFile"
                (resFile)="setBackgroundImage($event!)"
                label="{{ backgroundUploadInput }}"
                placeholder="{{ placeholderUploadInput }}"
              ></app-upload-input>
            </div>
          </div>

          <div class="row mb-3">
            @if (fromParent === "modal-page") {
              <div class="col-4">
                <label
                  >{{
                    "reporting.create_report.form.font_size" | translate
                  }}&nbsp;
                  @if (fromParent !== "modal-page") {
                    <span class="required-mark">*</span>
                  }
                </label>
                <mat-select
                  disableOptionCentering
                  formControlName="fontSize"
                  class="wsm-input"
                  [panelClass]="'wsm-custom-select'"
                >
                  @for (fontSize of fontSizes; track fontSize) {
                    <mat-option [value]="fontSize">
                      {{ fontSize?.label }}
                    </mat-option>
                  }
                </mat-select>
              </div>
            }
            @if (fromParent !== "modal-page") {
              <div class="col-4">
                <label
                  >{{ "theme.form.title_color" | translate }}&nbsp;
                  @if (fromParent === "static") {
                    <span class="required-mark">*</span>
                  }
                </label>
                <div class="input-group">
                  <input
                    formControlName="titleColor"
                    #titleColor
                    type="text"
                    class="wsm-input w-100"
                    placeholder="{{
                      'theme.form.placeholder.color' | translate
                    }}"
                  />
                  <div
                    class="circle-input rounded-circle"
                    cpPosition="bottom"
                    (colorPickerChange)="
                      themeForm.get('titleColor')?.setValue($event)
                    "
                    [cpOKButton]="true"
                    [cpEyeDropper]="true"
                    [cpDisabled]="themeForm.get('titleColor')?.disabled!"
                    cpCancelButtonClass="pickerOkBtn"
                    [cpOKButtonClass]="'pickerOkBtn'"
                    cpCancelButtonText="Clear"
                    cpOKButtonText="Select"
                    (colorPickerCancel)="onColorReset('titleColor')"
                    [(colorPicker)]="titleColor.value"
                    [style.background]="titleColor.value"
                  ></div>
                </div>
              </div>
            }
            <div class="col-4">
              <label
                >{{ "theme.form.page_color" | translate }}&nbsp;
                @if (fromParent !== "modal-page") {
                  <span class="required-mark">*</span>
                }
              </label>
              <div class="input-group">
                <input
                  formControlName="pageColor"
                  #pageColor
                  type="text"
                  class="wsm-input w-100"
                  placeholder="{{ 'theme.form.placeholder.color' | translate }}"
                />
                <div
                  class="circle-input rounded-circle"
                  (colorPickerChange)="
                    themeForm.get('pageColor')?.setValue($event)
                  "
                  (colorPickerCancel)="onColorReset('pageColor')"
                  [cpOKButton]="true"
                  [cpEyeDropper]="true"
                  [cpDisabled]="themeForm.get('pageColor')?.disabled!"
                  [cpCancelButton]="true"
                  cpCancelButtonText="Clear"
                  cpCancelButtonClass="pickerOkBtn"
                  [cpOKButtonClass]="'pickerOkBtn'"
                  [cpPosition]="'left'"
                  cpOKButtonText="Select"
                  [(colorPicker)]="pageColor.value"
                  [style.background]="pageColor.value"
                ></div>
              </div>
            </div>
            <div class="col-4">
              <label
                >{{ "theme.form.body_color" | translate }}&nbsp;
                @if (fromParent !== "modal-page") {
                  <span class="required-mark">*</span>
                }
              </label>
              <div class="input-group">
                <input
                  formControlName="bodyColor"
                  #bodyColor
                  type="text"
                  class="wsm-input w-100"
                  placeholder="{{ 'theme.form.placeholder.color' | translate }}"
                />
                <div
                  class="circle-input rounded-circle"
                  [cpDisabled]="themeForm.get('bodyColor')?.disabled!"
                  (colorPickerChange)="
                    themeForm.get('bodyColor')?.setValue($event)
                  "
                  (colorPickerCancel)="onColorReset('bodyColor')"
                  [cpOKButton]="true"
                  [cpEyeDropper]="true"
                  [cpPosition]="'left'"
                  [cpOKButtonClass]="'pickerOkBtn'"
                  [cpCancelButton]="true"
                  cpOKButtonText="Select"
                  cpCancelButtonText="Clear"
                  cpCancelButtonClass="pickerOkBtn"
                  [(colorPicker)]="bodyColor.value"
                  [style.background]="bodyColor.value"
                ></div>
              </div>
            </div>
          </div>
          <div class="row mb-3">
            @if (fromParent === "modal-page") {
              <div class="col-4">
                <label>{{ "theme.form.title_color" | translate }}</label>
                <div class="input-group">
                  <input
                    formControlName="titleColor"
                    #titleColor
                    type="text"
                    class="wsm-input w-100"
                    placeholder="{{
                      'theme.form.placeholder.color' | translate
                    }}"
                  />
                  <div
                    class="circle-input rounded-circle"
                    cpPosition="bottom"
                    [cpEyeDropper]="true"
                    (colorPickerChange)="
                      themeForm.get('titleColor')?.setValue($event)
                    "
                    (colorPickerCancel)="onColorReset('titleColor')"
                    [cpOKButton]="true"
                    cpCancelButtonText="Clear"
                    [cpCancelButton]="true"
                    cpCancelButtonClass="pickerOkBtn"
                    [cpOKButtonClass]="'pickerOkBtn'"
                    cpOKButtonText="Select"
                    [(colorPicker)]="titleColor.value"
                    [style.background]="titleColor.value"
                  ></div>
                </div>
              </div>
            }

            <div class="col-4">
              <label
                >{{ "theme.form.widget_background_color" | translate }}&nbsp;
                @if (fromParent !== "modal-page") {
                  <span class="required-mark">*</span>
                }
              </label>
              <div class="input-group">
                <input
                  formControlName="widgetBackgroundColor"
                  #widgetBackgroundColor
                  type="text"
                  class="wsm-input w-100"
                  placeholder="{{ 'theme.form.placeholder.color' | translate }}"
                />
                <div
                  class="circle-input rounded-circle"
                  [cpOKButton]="true"
                  [cpEyeDropper]="true"
                  [cpPosition]="'right'"
                  [cpDisabled]="
                    themeForm.get('widgetBackgroundColor')?.disabled!
                  "
                  (colorPickerChange)="
                    themeForm.get('widgetBackgroundColor')?.setValue($event)
                  "
                  (colorPickerCancel)="onColorReset('widgetBackgroundColor')"
                  [cpOKButtonClass]="'pickerOkBtn'"
                  [cpCancelButton]="true"
                  cpOKButtonText="Select"
                  cpCancelButtonText="Clear"
                  cpCancelButtonClass="pickerOkBtn"
                  [(colorPicker)]="widgetBackgroundColor.value"
                  [style.background]="widgetBackgroundColor.value"
                ></div>
              </div>
            </div>
            <div class="col-4">
              <label
                >{{ "theme.form.border_color" | translate }}&nbsp;
                @if (fromParent !== "modal-page") {
                  <span class="required-mark">*</span>
                }
              </label>
              <div class="input-group">
                <input
                  formControlName="borderColor"
                  #borderColor
                  type="text"
                  class="wsm-input w-100"
                  placeholder="{{ 'theme.form.placeholder.color' | translate }}"
                />
                <div
                  class="circle-input rounded-circle"
                  [cpDisabled]="themeForm.get('borderColor')?.disabled!"
                  [cpOKButton]="true"
                  [cpEyeDropper]="true"
                  [cpPosition]="'left'"
                  (colorPickerChange)="
                    themeForm.get('borderColor')?.setValue($event)
                  "
                  (colorPickerCancel)="onColorReset('borderColor')"
                  [cpOKButtonClass]="'pickerOkBtn'"
                  [cpCancelButton]="true"
                  cpOKButtonText="Select"
                  cpCancelButtonText="Clear"
                  cpCancelButtonClass="pickerOkBtn"
                  [(colorPicker)]="borderColor.value"
                  [style.background]="borderColor.value"
                ></div>
              </div>
            </div>
          </div>
        </mat-expansion-panel>
        <!--WIDGET CHARTS COLOR PALETTE-->
        <mat-expansion-panel class="mat-elevation-z0">
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{ "theme.form.section.widget_chart_colors" | translate }}
              &nbsp;
              @if (fromParent !== "modal-page") {
                <span class="required-mark">*</span>
              }
            </mat-panel-title>
          </mat-expansion-panel-header>
          @if (colorsPalette.length === 0) {
            <div class="col-12">
              <app-cta-container>
                <div
                  class="d-flex justify-content-center flex-column align-items-center p-3"
                >
                  <p class="mb-1">
                    {{ "theme.form.widget_chart_color_cta" | translate }}
                  </p>
                  <button
                    [disabled]="!canEditForm && !isSuperOrOrgAdmin"
                    (click)="addColor()"
                    class="wsm-btn wsm-btn-primary"
                  >
                    {{ "theme.form.button.chart_color_cta" | translate }}
                  </button>
                </div>
              </app-cta-container>
            </div>
          }
          @if (colorsPalette.length > 0) {
            <div class="row mt-3 mb-3">
              @for (
                color of colorsPalette.controls;
                track color;
                let i = $index
              ) {
                <div class="col-4 mb-3">
                  <div
                    class="d-flex justify-content-between align-items-center mb-1"
                  >
                    <label class="mb-0">{{
                      "theme.form.widget_chart_color" | translate
                    }}</label>
                    <button
                      [disabled]="!canEditForm && !isSuperOrOrgAdmin"
                      (click)="removeColor(i, $event)"
                      class="wsm-btn wsm-btn-outline-icon wsm-btn-tiny-icon"
                    >
                      <i class="fa-regular fa-xmark"></i>
                    </button>
                  </div>
                  <div class="input-group">
                    <input
                      #colorw
                      type="text"
                      class="wsm-input w-100"
                      (keypress)="onPreventEnterKey($event)"
                      [formControl]="$any(color)"
                      placeholder="{{
                        'theme.form.placeholder.color' | translate
                      }}"
                    />
                    <div
                      class="circle-input rounded-circle"
                      [cpDisabled]="$any(color)?.disabled!"
                      (colorPickerChange)="$any(color).setValue($event)"
                      (colorPickerCancel)="onColorPaletteReset(i)"
                      [cpOKButton]="true"
                      [cpEyeDropper]="true"
                      [cpPosition]="i === 2 ? 'left' : 'right'"
                      [cpOKButtonClass]="'pickerOkBtn'"
                      [cpCancelButton]="true"
                      cpCancelButtonText="Clear"
                      cpCancelButtonClass="pickerOkBtn"
                      cpOKButtonText="Select"
                      [(colorPicker)]="colorw.value"
                      [style.background]="colorw.value"
                    ></div>
                  </div>
                </div>
              }
              @if (colorsPalette.length <= 4) {
                <div class="col-4 d-flex justify-content-start">
                  <button
                    [disabled]="!canEditForm && !isSuperOrOrgAdmin"
                    (click)="addColor()"
                    class="wsm-btn wsm-btn-primary align-self-center mt-4"
                  >
                    {{ "theme.form.button.chart_color_cta" | translate }}
                  </button>
                </div>
              }
            </div>
          }
        </mat-expansion-panel>

        <mat-expansion-panel class="mat-elevation-z0">
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{ "theme.form.section.table_colors" | translate }}
            </mat-panel-title>
          </mat-expansion-panel-header>

          <div class="row mb-3 mt-3">
            <div class="col-4">
              <label>{{
                "theme.form.header_background_color" | translate
              }}</label>
              <div class="input-group">
                <input
                  formControlName="tableHeaderBgColor"
                  #headerBackgroundColor
                  type="text"
                  class="wsm-input w-100"
                  placeholder="{{ 'theme.form.placeholder.color' | translate }}"
                />
                <div
                  class="circle-input rounded-circle"
                  cpPosition="bottom"
                  [cpDisabled]="themeForm.get('tableHeaderBgColor')?.disabled!"
                  (colorPickerChange)="
                    themeForm.get('tableHeaderBgColor')?.setValue($event)
                  "
                  (colorPickerCancel)="onColorReset('tableHeaderBgColor')"
                  [cpOKButton]="true"
                  [cpEyeDropper]="true"
                  [cpCancelButton]="true"
                  [cpOKButtonClass]="'pickerOkBtn'"
                  cpOKButtonText="Select"
                  cpCancelButtonText="Clear"
                  cpCancelButtonClass="pickerOkBtn"
                  [(colorPicker)]="headerBackgroundColor.value"
                  [style.background]="headerBackgroundColor.value"
                ></div>
              </div>
            </div>
            <div class="col-4">
              <label>{{ "theme.form.header_border_color" | translate }}</label>
              <div class="input-group">
                <input
                  formControlName="tableHeaderBorderColor"
                  #headerBorderColor
                  type="text"
                  class="wsm-input w-100"
                  placeholder="{{ 'theme.form.placeholder.color' | translate }}"
                />
                <div
                  class="circle-input rounded-circle"
                  [cpOKButton]="true"
                  [cpPosition]="'left'"
                  [cpEyeDropper]="true"
                  [cpDisabled]="
                    themeForm.get('tableHeaderBorderColor')?.disabled!
                  "
                  [cpCancelButton]="true"
                  (colorPickerChange)="
                    themeForm.get('tableHeaderBorderColor')?.setValue($event)
                  "
                  (colorPickerCancel)="onColorReset('tableHeaderBorderColor')"
                  [cpOKButtonClass]="'pickerOkBtn'"
                  cpOKButtonText="Select"
                  cpCancelButtonText="Clear"
                  cpCancelButtonClass="pickerOkBtn"
                  [(colorPicker)]="headerBorderColor.value"
                  [style.background]="headerBorderColor.value"
                ></div>
              </div>
            </div>
            <div class="col-4">
              <label>{{ "theme.form.row_border_color" | translate }}</label>
              <div class="input-group">
                <input
                  formControlName="tableRowBorderColor"
                  #rowBorderColor
                  type="text"
                  class="wsm-input w-100"
                  placeholder="{{ 'theme.form.placeholder.color' | translate }}"
                />
                <div
                  class="circle-input rounded-circle"
                  [cpDisabled]="themeForm.get('tableRowBorderColor')?.disabled!"
                  [cpEyeDropper]="true"
                  (colorPickerChange)="
                    themeForm.get('tableRowBorderColor')?.setValue($event)
                  "
                  (colorPickerCancel)="onColorReset('tableRowBorderColor')"
                  [cpOKButton]="true"
                  [cpPosition]="'left'"
                  [cpCancelButton]="true"
                  [cpOKButtonClass]="'pickerOkBtn'"
                  cpOKButtonText="Select"
                  cpCancelButtonText="Clear"
                  cpCancelButtonClass="pickerOkBtn"
                  [(colorPicker)]="rowBorderColor.value"
                  [style.background]="rowBorderColor.value"
                ></div>
              </div>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-4">
              <label>{{ "theme.form.row_background_color" | translate }}</label>
              <div class="input-group">
                <input
                  formControlName="tableRowBgColor"
                  #rowBackgroundColor
                  type="text"
                  class="wsm-input w-100"
                  placeholder="{{ 'theme.form.placeholder.color' | translate }}"
                />
                <div
                  class="circle-input rounded-circle"
                  cpPosition="bottom"
                  [cpDisabled]="themeForm.get('tableRowBgColor')?.disabled!"
                  (colorPickerChange)="
                    themeForm.get('tableRowBgColor')?.setValue($event)
                  "
                  (colorPickerCancel)="onColorReset('tableRowBgColor')"
                  [cpOKButton]="true"
                  [cpEyeDropper]="true"
                  cpOKButtonClass="pickerOkBtn"
                  cpOKButtonText="Select"
                  cpCancelButtonText="Clear"
                  [cpCancelButton]="true"
                  cpCancelButtonClass="pickerOkBtn"
                  [(colorPicker)]="rowBackgroundColor.value"
                  [style.background]="rowBackgroundColor.value"
                ></div>
              </div>
            </div>
            <div class="col-4">
              <label>{{ "theme.form.striped_rows" | translate }}</label>
              <mat-slide-toggle
                formControlName="stripedRows"
                class="example-margin"
              ></mat-slide-toggle>
            </div>
          </div>
        </mat-expansion-panel>

        <mat-expansion-panel class="mat-elevation-z0">
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{ "theme.form.section.comparison_labels" | translate }}
            </mat-panel-title>
          </mat-expansion-panel-header>

          <div class="row mt-3 mb-3">
            <div class="col-6">
              <label>{{ "theme.form.color_schema" | translate }}</label>
              <mat-select
                disableOptionCentering
                class="wsm-input w-100"
                formControlName="schemaIndicator"
                [panelClass]="{
                  'wsm-custom-select': true,
                  'is-dialog': fromParent !== 'static',
                }"
              >
                <mat-option [value]="'opt-1'"
                  >{{ "common.option" | translate }} 1</mat-option
                >
                <mat-option [value]="'opt-2'"
                  >{{ "common.option" | translate }} 2</mat-option
                >
                <mat-option [value]="'opt-3'"
                  >{{ "common.option" | translate }} 3</mat-option
                >
                <mat-option [value]="'opt-4'"
                  >{{ "common.option" | translate }} 4</mat-option
                >
              </mat-select>
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
    <div class="row mb-2 mt-2">
      <div class="col-12">
        <div class="row d-flex justify-content-end">
          <div class="d-flex" style="flex-basis: fit-content">
            <button
              [disabled]="isSavingData"
              (click)="onCancel()"
              class="wsm-btn wsm-btn-outline w-100 me-2"
            >
              {{ "theme.form.button.cancel" | translate }}
            </button>
            @if (!themeToEdit?._id) {
              <button
                [disabled]="
                  themeForm.invalid ||
                  isSavingData ||
                  (isReadOnly && !isSuperOrOrgAdmin)
                "
                (click)="onApply()"
                class="wsm-btn wsm-btn-primary btn-apply-theme w-100"
              >
                @if (!isSavingData) {
                  <span>
                    {{ "theme.form.button.apply" | translate }}
                  </span>
                }
                @if (isSavingData) {
                  <div
                    class="spinner-border spinner-border-sm text-light"
                    role="status"
                  >
                    <span class="visually-hidden">Loading...</span>
                  </div>
                }
              </button>
            }
            @if (themeToEdit?._id) {
              @if (!isSavingData) {
                <div class="btn-group" role="group">
                  <button
                    (click)="onApply(false)"
                    type="button"
                    class="wsm-btn wsm-btn-primary"
                    [disabled]="
                      themeForm.invalid ||
                      isSavingData ||
                      (isReadOnly && !isSuperOrOrgAdmin) ||
                      !canSaveTheme
                    "
                  >
                    {{ "theme.form.button.apply" | translate }}
                  </button>
                  <button
                    [matMenuTriggerFor]="menu"
                    type="button"
                    class="wsm-btn wsm-btn-primary"
                    [disabled]="
                      themeForm.invalid ||
                      isSavingData ||
                      (isReadOnly && !isSuperOrOrgAdmin) ||
                      !canSaveTheme
                    "
                  >
                    <i class="fa-solid fa-angle-down"></i>
                  </button>
                  <mat-menu #menu="matMenu">
                    <button
                      id="btnSaveTheme"
                      (click)="onApply(false)"
                      mat-menu-item
                    >
                      {{ "theme.form.button.apply" | translate }}
                    </button>
                    <button
                      id="btnSaveThemeAsACopy"
                      (click)="onApply(true)"
                      mat-menu-item
                    >
                      {{ "theme.form.button.save_as_a_copy" | translate }}
                    </button>
                  </mat-menu>
                </div>
              }
              @if (isSavingData) {
                <button class="wsm-btn wsm-btn-primary btn-apply-theme w-100">
                  <div
                    class="spinner-border spinner-border-sm text-light"
                    role="status"
                  >
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </button>
              }
            }
          </div>
        </div>
      </div>
    </div>
  </form>
  <div class="col-lg-6 col-md-6 col-sm-12">
    <!--PREVIEW HERE-->
    <app-theme-preview
      [theme]="themeForm.value"
      [reportTheme]="themeToPreview"
      [bckImgSrc]="backgroundSource"
      [imgSrc]="logoSource"
      class="h-100"
    ></app-theme-preview>
  </div>
</div>
