import {Component, TemplateRef, ViewChild} from '@angular/core';

@Component({
    selector: 'app-cta-container',
    templateUrl: './cta-container.component.html',
    styleUrls: ['./cta-container.component.scss'],
    standalone: true,
})
export class CtaContainerComponent {
  @ViewChild(TemplateRef) ctaContent: TemplateRef<any>;
}
