import {Component, Inject, OnInit} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogContent, MatDialogActions, MatDialogClose } from '@angular/material/dialog';
import { NgxIntlTelInputModule } from '@whiteshark-media/ngx-intl-tel-input-app';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'app-dialog-copy-theme',
    templateUrl: './dialog-copy-theme.component.html',
    standalone: true,
    imports: [
        MatDialogContent,
        FormsModule,
        ReactiveFormsModule,
        NgxIntlTelInputModule,
        MatDialogActions,
        MatDialogClose,
        TranslateModule,
    ],
})
export class DialogCopyThemeComponent implements OnInit {
  public isLoading: boolean;
  public copyForm: UntypedFormGroup;
  constructor(
    public dialogRef: MatDialogRef<DialogCopyThemeComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}

  ngOnInit(): void {
    if (this.data) {
      this.copyForm = new UntypedFormGroup({
        title: new UntypedFormControl(
          this.data.title || '',
          Validators.required
        ),
      });
    }
  }

  onSubmit(): void {
    if (this.copyForm.invalid) {
      this.copyForm.markAsDirty();
    } else {
      this.dialogRef.close({title: this.copyForm.get('title')?.value});
    }
  }
}
