<div class="overlay-container">
  <app-theme-builder
    [fromParent]="'static'"
    [themeToEdit]="themeToEdit"
    [isSavingData]="isSavingData"
    [parentLogoFile]="logoFile!"
    [parentBackgroundFile]="backgroundFile!"
    [backButton]="true"
    (goBack)="onGoBack()"
    [isFromReportDialog]="isFromReport"
    (sendTheme)="onSetTheme($event!)"
  />
  <app-loading [generalLoading]="loadingImage" />
</div>
