import {
  Component,
  EventEmitter,
  inject,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {combineLatest, Subject, takeUntil} from 'rxjs';
import {ApiService} from 'src/app/core/services/api.service';
import {BrandingService} from 'src/app/core/services/branding.service';
import {ReportTheme} from 'src/app/shared/models';
import {DialogCopyThemeComponent} from '../../components/dialog-copy-theme/dialog-copy-theme.component';
import {ThemeBuilderComponent} from '../../../../shared/components/theme-builder/theme-builder.component';
import {LoadingComponent} from '../../../../shared/components/loading/loading.component';
import {NotificationService} from 'src/app/core/services/notification.service';
import {TrackingService} from 'src/app/core/services/tracking.service';
import {ConfirmationDialogComponent} from 'src/app/shared/dialogs/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-theme-setting',
  templateUrl: './theme-settings.component.html',
  standalone: true,
  imports: [ThemeBuilderComponent, LoadingComponent],
})
export class ThemeSettingComponent implements OnDestroy {
  @Input() isFromReport?: boolean;
  @Output() setReportTheme = new EventEmitter<any>();
  @Output() closeReportThemeModal = new EventEmitter<any>();

  public themeToEdit: ReportTheme;
  public isEdit = false;
  protected onDestroy = new Subject<void>();

  public isSavingData: boolean;
  public notiNewTheme: string;
  public notiUpdateTheme: string;

  public logoFile: File | null;
  public backgroundFile: File | null;
  public loadingImage: boolean;

  public resultTheme: ReportTheme;

  private brandingService = inject(BrandingService);
  private apiService = inject(ApiService);
  private notificationService = inject(NotificationService);
  private router = inject(Router);
  private translate = inject(TranslateService);
  private dialog = inject(MatDialog);
  private trackingService = inject(TrackingService);

  constructor() {
    this.getTranslations();
    this.brandingService
      .getReportTheme()
      .pipe(takeUntil(this.onDestroy))
      .subscribe({
        next: (res) => {
          if (res && res?._id) {
            this.isEdit = true;
            this.loadingImage = true;
            this.themeToEdit = res;
            if (this.themeToEdit.backgroundImage || this.themeToEdit.logo) {
              this.handleGetImages();
            } else {
              this.loadingImage = false;
            }
          } else {
            this.loadingImage = false;
          }
        },
      });
  }

  ngOnInit(): void {
    //
  }

  handleGetImages(): void {
    this.loadingImage = true;
    if (this.themeToEdit?.logo && this.themeToEdit?.backgroundImage) {
      this.getImages();
    }

    if (this.themeToEdit?.logo && !this.themeToEdit?.backgroundImage) {
      this.loadingImage = true;
      this.backgroundFile = null;
      const logo$ = this.apiService.getImage(
        this.themeToEdit.logo,
        'reportThemeLogo'
      );
      logo$.subscribe({
        next: (res) => {
          this.logoFile = new File([res], this.themeToEdit.logo as string);
          this.loadingImage = false;
        },
        error: (err) => {
          this.notificationService.error(err.error.error_description, 5000);
          this.loadingImage = false;
          console.error(err);
        },
      });
    }

    if (this.themeToEdit?.backgroundImage && !this.themeToEdit?.logo) {
      this.loadingImage = true;
      this.logoFile = null;
      const background$ = this.apiService.getImage(
        this.themeToEdit.backgroundImage,
        'reportThemeBackground'
      );
      background$.subscribe({
        next: (res) => {
          this.backgroundFile = new File(
            [res],
            this.themeToEdit.backgroundImage
          );
          this.loadingImage = false;
        },
        error: (err) => {
          this.notificationService.error(err.error.error_description, 5000);
          this.loadingImage = false;
          console.error(err);
        },
      });
    }
  }

  getImages(): void {
    this.loadingImage = true;
    const logo$ = this.apiService.getImage(
      this.themeToEdit.logo as string,
      'reportThemeLogo',
      this.themeToEdit._id
    );
    const background$ = this.apiService.getImage(
      this.themeToEdit.backgroundImage,
      'reportThemeBackground',
      this.themeToEdit._id
    );

    const combine = combineLatest([logo$, background$]);

    combine.subscribe({
      next: ([logo, background]) => {
        if (logo && background) {
          this.logoFile = new File([logo], this.themeToEdit.logo as string);
          this.backgroundFile = new File(
            [background],
            this.themeToEdit.backgroundImage
          );
          this.loadingImage = false;
        }
      },
      error: (err) => {
        console.error(err);
        this.notificationService.error(err.error.error_description, 5000);
        this.loadingImage = false;
      },
    });
  }

  onSetTheme(event: ReportTheme): void {
    const payload = this.buildPayload(event);
    if (!this.isEdit) {
      this.isSavingData = true;
      this.saveTheme(payload);
    }
    if (this.isEdit) {
      this.onHandleEdit(payload);
    }
  }

  onHandleEdit(payload): void {
    // it's a theme´s copy
    if (payload?.isACopy) {
      const dialogRef = this.dialog.open(DialogCopyThemeComponent, {
        data: {
          title: payload.name,
        },
        width: '45vw',
      });
      const dialogSub = this.router.events.subscribe(() => {
        dialogRef.close();
      });
      dialogRef.afterClosed().subscribe((res) => {
        if (res.title) {
          this.isSavingData = true;
          this.isEdit = false;
          payload.name = res.title;
          this.saveTheme(payload);
        } else {
          this.isSavingData = false;
          return;
        }
      });
    }

    // it's not a theme´s copy. Just update the theme
    if (!payload?.isACopy) {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        data: {
          title: this.translate.instant(
            'theme.confirmation_dialog.update_theme.title'
          ),
          content: this.translate.instant(
            'theme.confirmation_dialog.update_theme.content'
          ),
          confirmButton: this.translate.instant(
            'theme.confirmation_dialog.update_theme.confirmButton'
          ),
        },
        width: '40vw',
      });

      const dialogSub = this.router.events.subscribe(() => {
        dialogRef.close();
      });

      dialogRef.afterClosed().subscribe((res) => {
        if (res === true) {
          this.isSavingData = true;
          this.updateTheme(payload);
        } else {
          return;
        }
      });
    }
  }

  updateTheme(payload): void {
    this.brandingService
      .updateReportTheme(payload, this.themeToEdit._id as string)
      .subscribe({
        next: (res) => {
          if (res) {
            this.resultTheme = res;
            if (payload.backgroundImageFile || payload.logoFile) {
              this.handleUploadImages(payload, this.themeToEdit._id);
            } else {
              this.isSavingData = false;
              this.notificationService.success(this.notiUpdateTheme, 3000);
              this.onGoBack();
              this.goToGallery();
            }
          }
        },
      });
  }

  saveTheme(payload): void {
    this.brandingService.saveReportTheme(payload).subscribe({
      next: (res) => {
        if (res) {
          this.trackingService.trackEvent('Create Theme');
          this.resultTheme = res;
          if (payload.backgroundImageFile || payload.logoFile) {
            this.handleUploadImages(payload, res._id);
          } else {
            this.isSavingData = false;
            this.notificationService.success(this.notiNewTheme, 3000);
            this.onGoBack();
          }
        }
      },
      error: (err) => {
        this.notificationService.error(err.error.error_description, 5000);
      },
    });
  }

  handleUploadImages(payload, themeId: string | undefined): void {
    if (!this.isEdit) {
      if (payload.backgroundImageFile && payload.logoFile) {
        this.uploadImages(
          payload.logoFile,
          payload.backgroundImageFile,
          themeId
        );
      }

      if (payload.backgroundImageFile && !payload.logoFile) {
        const bkg$ = this.apiService.uploadPicture(
          payload.backgroundImageFile,
          'reportThemeBackground',
          themeId
        );
        bkg$.subscribe({
          next: (res) => {
            if (res.body != undefined) {
              this.resultTheme.backgroundImage = res.body.file;
            }
          },
          error: (err) => {
            this.isSavingData = false;
            this.notificationService.error(err.error.error_description, 5000);
          },
          complete: () => {
            if (this.isEdit) {
              this.notificationService.success(this.notiUpdateTheme, 3000);
            }
            if (!this.isEdit) {
              this.notificationService.success(this.notiNewTheme, 3000);
            }
            this.isSavingData = false;
            this.onGoBack();
          },
        });
      }

      if (!payload.backgroundImageFile && payload.logoFile) {
        const logo$ = this.apiService.uploadPicture(
          payload.logoFile,
          'reportThemeLogo',
          themeId
        );
        logo$.subscribe({
          next: () => {
            //
          },
          error: (err) => {
            this.isSavingData = false;
            console.error(err);
            this.notificationService.error(err.error.error_description, 5000);
          },
          complete: () => {
            if (this.isEdit) {
              this.notificationService.success(this.notiUpdateTheme, 3000);
            }
            if (!this.isEdit) {
              this.notificationService.success(this.notiNewTheme, 3000);
            }
            this.isSavingData = false;
            this.onGoBack();
          },
        });
      }

      if (!payload.backgroundImageFile && payload.logoFile) {
        this.isSavingData = false;
        this.onGoBack();
      }
    }

    if (this.isEdit) {
      if (payload.backgroundImageFile && payload.logoFile) {
        if (
          payload.backgroundImage !== this.themeToEdit?.backgroundImage &&
          payload.logo !== this.themeToEdit.logo
        ) {
          this.uploadImages(
            payload.logoFile,
            payload.backgroundImageFile,
            themeId
          );
        }
      }
      if (payload.backgroundImageFile) {
        if (payload.backgroundImage !== this.themeToEdit.backgroundImage) {
          const bkg$ = this.apiService.uploadPicture(
            payload.backgroundImageFile,
            'reportThemeBackground',
            themeId
          );
          bkg$.subscribe({
            next: (res) => {
              if (res.body !== undefined) {
                this.resultTheme.backgroundImage = res.body.file;

                if (this.isEdit) {
                  this.notificationService.success(this.notiUpdateTheme, 3000);
                }
                if (!this.isEdit) {
                  this.notificationService.success(this.notiNewTheme, 3000);
                }
                this.onGoBack();

                this.isSavingData = false;
              }
            },
            error: (err) => {
              this.isSavingData = false;
              this.notificationService.error(err.error.error_description, 5000);
            },
            complete: () => {},
          });
        }
      }

      if (payload.logoFile) {
        if (payload.logo !== this.themeToEdit.logo) {
          const logo$ = this.apiService.uploadPicture(
            payload.logoFile,
            'reportThemeLogo',
            themeId
          );
          logo$.subscribe({
            next: (res) => {
              if (res.body != undefined) {
                this.resultTheme.logo = res.body.file;
              }
            },
            error: (err) => {
              this.isSavingData = false;
              console.error(err);
              this.notificationService.error(err.error.error_description, 5000);
            },
            complete: () => {
              if (this.isEdit) {
                this.notificationService.success(this.notiUpdateTheme, 3000);
              }
              if (!this.isEdit) {
                this.notificationService.success(this.notiNewTheme, 3000);
              }
              this.isSavingData = false;
              this.onGoBack();
            },
          });
        }
      }

      this.isSavingData = false;
      this.onGoBack();
    }
  }

  uploadImages(logoFile?: any, backgroundFile?: any, themeId?: string): void {
    const logo$ = this.apiService.uploadPicture(
      logoFile,
      'reportThemeLogo',
      themeId
    );
    const bkg$ = this.apiService.uploadPicture(
      backgroundFile,
      'reportThemeBackground',
      themeId
    );

    const combine = combineLatest([logo$, bkg$]);

    combine.subscribe({
      next: ([logo, bkg]) => {
        if (logo.body !== undefined && bkg.body !== undefined) {
          this.resultTheme.backgroundImage = bkg.body.file;
          this.resultTheme.logo = logo.body.file;
        }
      },
      error: (err) => {
        this.isSavingData = false;
        console.error(err);
        this.notificationService.error(err.error.error_description, 5000);
      },
      complete: () => {
        if (this.isEdit) {
          this.notificationService.success(this.notiUpdateTheme, 3000);
        }
        if (!this.isEdit) {
          this.notificationService.success(this.notiNewTheme, 3000);
        }
        this.isSavingData = false;
        this.goToGallery();
      },
    });
  }

  buildPayload(t: ReportTheme, tId?: number): any {
    let payload = {};
    if (!tId) {
      // new theme
      payload = {
        backgroundColor: t.widgetBackgroundColor,
        ...t,
      };
    }
    return payload;
  }

  goToGallery(): void {
    this.router.navigate(['/themes']);
  }

  getTranslations(): void {
    this.translate
      .get('theme.notification.theme_saved')
      .subscribe({next: (res) => (this.notiNewTheme = res)});
    this.translate
      .get('theme.notification.theme_updated')
      .subscribe({next: (res) => (this.notiUpdateTheme = res)});
  }

  onGoBack(): void {
    if (!this.isFromReport) {
      this.router.navigate(['/themes']);
    }
    if (this.isFromReport && this.resultTheme) {
      this.resultTheme.colors = [
        this.resultTheme.widgetBackgroundColor as string,
        this.resultTheme.bodyColor as string,
        this.resultTheme.borderColor as string,
        this.resultTheme.pageColor as string,
        this.resultTheme.titleColor as string,
      ];
      this.closeReportThemeModal.emit({theme: this.resultTheme});
      this.brandingService.clearSharedReportTheme();
    }
    if (this.isFromReport && !this.resultTheme) {
      this.closeReportThemeModal.emit({theme: null});
      this.brandingService.clearSharedReportTheme();
    }
  }

  ngOnDestroy(): void {
    this.brandingService.clearSharedReportTheme();
    this.onDestroy.next();
    this.onDestroy.complete();
  }
}
